import { defineStore } from "pinia";
import type { QDialog } from "quasar";
import { Dialog } from "quasar";

import PopUpComponent from "~/services/PopUp/PopUp.vue";
import MobileDownUpPopUp from "~/services/PopUp/MobileDownUpPopIp.vue";

type DialogPosition =
  | "standard"
  | "top"
  | "right"
  | "bottom"
  | "left"
  | undefined;

export const PopUpServices = defineStore("PopUpServices", {
  state: () => ({
    popUpRef: undefined as Ref<QDialog | undefined> | undefined,
    createPopUp: false,
    currentPopUp: "",
    currentPopUpData: null as any,
    PopUpPersistent: false as boolean | undefined,
    position: "standart" as DialogPosition,
    maximized: undefined as boolean | undefined,
    dataOnClose: undefined as any,
  }),
  actions: {
    callPopUp(args: {
      componentName: string;
      data?: any;
      persistent?: boolean;
      position?: DialogPosition;
      maximized?: boolean;
      isMobil?: boolean;
    }) {
      this.maximized = args.maximized;
      this.currentPopUp = args.componentName;
      this.currentPopUpData = args.data;
      this.createPopUp = true;
      this.position = args.position;
      this.PopUpPersistent = args.persistent;
      return new Promise<any>((resolve) => {
        Dialog.create({
          component: args.isMobil ? MobileDownUpPopUp : PopUpComponent,
          componentProps: { data: args.data },
          persistent: args.persistent,
          position: args.position,
          maximized: args.maximized,
          ok: {
            push: true,
          },
          cancel: {
            push: true,
          },
        })
          .onOk((returnedData) => {
            resolve(returnedData ?? true);
          })
          .onCancel(() => {
            resolve(false);
          })
          .onDismiss(() => {
            resolve(false);
            this.maximized = false;
          });
      });
    },
  },
});
