<template>
  <q-dialog
    ref="dialogRef"
    :maximized="createPopUp.$state.maximized"
    :position="createPopUp.$state.position"
    :persistent="createPopUp.$state.PopUpPersistent"
    style="z-index: 99"
  >
    <component :is="createPopUp.$state.currentPopUp" :data="createPopUp.$state.currentPopUpData" @hide_popup="closePopUp"/>
  </q-dialog>
</template>

<script setup lang="ts">
import { PopUpServices } from "~/services/PopUp/callPopUp";
import { useDialogPluginComponent, QDialog } from "quasar";

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const createPopUp = PopUpServices();

const closePopUp = (state: "ok" | "ca",data?:any) => {
  createPopUp.$state.currentPopUp = "";
  if (state === "ok") {
    onDialogOK(data);
  } else if (state === "ca") {
    onDialogCancel();
  } else {
    onDialogHide();
  }
};

onMounted(() => {
  createPopUp.$state.popUpRef = dialogRef.value;
});
</script>

<style lang="scss"></style>
